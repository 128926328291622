import React from 'react'
import Layout from '../components/Layout'
import FeaturedQuiltBlock from '../components/FeaturedQuiltBlock'

const NotFoundPage = () => (
  <Layout>
    <div className="max-w-screen-xl mx-auto my-20 text-center">
      <h1 className="text-3xl font-caps uppercase mb-5">Oops. You hit a dead end.</h1>
      <p className="text-xl">Check out this week's featured story.</p>
    </div>
    <div className="max-w-screen-xl mx-auto mb-20">
      <FeaturedQuiltBlock/>
    </div>
  </Layout>
)

export default NotFoundPage
