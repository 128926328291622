import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Button from '../components/Button'
import { GatsbyImage } from "gatsby-plugin-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allWpQuiltBlock(
        limit: 1
        filter: { quiltBlockFields: { featured: { eq: true } } }
      ) {
        edges {
          node {
            id
            title
            slug
            quiltBlockFields {
              description
              quiltImage {
                gatsbyImage(layout: CONSTRAINED, width: 1200, height: 1200)
                altText
              }
            }
          }
        }
      }
    }
  `)
  const featured = data.allWpQuiltBlock.edges.length
    ? data.allWpQuiltBlock.edges[0].node
    : 'nope'
  if (featured === 'nope') {
    return ''
  } else {
    return (
      <div className="flex p-8 flex-col md:flex-row">
        <div className="relative md:w-1/2">
          <Link
            to={`quilt/${featured.slug}`}
            state={{
              modal: true,
            }}
            className='group'
          >
            <GatsbyImage
              className="object-cover aspect-square w-full group-hover:grayscale group-hover:brightness-75 transition-all"
              image={featured.quiltBlockFields.quiltImage.gatsbyImage}
              alt={featured.quiltBlockFields.quiltImage.altText}
            />
          </Link>
        </div>
        <div className="md:p-8 md:w-1/2 mx-auto max-w-lg flex items-center justify-center">
          <div className="mt-8 md:mt-0">
            <Link
              className="hover:text-red transition-colors duration-150"
              to={`quilt/${featured.slug}`}
              state={{
                modal: true,
              }}
            >
            <h2 className="text-3xl font-bold mb-4">Featured This Week</h2>{' '}
            <h3 className="text-xl font-bold mb-4">{featured.title}</h3>
            </Link>
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: featured.quiltBlockFields.description }} />
            <Link to="/submit">
              <Button>Submit Your Story</Button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
